import React from "react"
//import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//import { Helmet } from 'react-helmet'

import '../css/header.css'
import '../../src/components/layout.css'
//import NavbarGlobal from "./navbar"
import SEO from '../components/seo'

const Header = () => (
  <>
    <SEO />
  </>
)


export default Header

